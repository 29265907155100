import { Card, Col, Container, Row, Tab, Tabs, Image } from "react-bootstrap"
import { Route, Routes, useNavigate } from "react-router-dom"

function SettingPage({닉네임}){
    let navigate = useNavigate()




    return(
        <>
        <Container fluid>
            <Row style={{margin : 10}}>
                <Col xs = {2}>
                    <Card Body className='UsernameBox'>장비창 암튼암튼 만들어야댐
                        <Row style={{paddingTop : 10}}>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/earing')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/head')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/neck')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                        </Row>
                        <Row style={{paddingTop : 10}}>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/weapon')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/up')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/sub')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                        </Row>
                        <Row style={{paddingTop : 10}}>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/dd')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/low')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/hand')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                        </Row>
                        <Row style={{paddingTop : 10}}>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/belt')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/leg')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/charm')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                        </Row>
                        <Row style={{paddingTop : 10}}>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/lring')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/arti')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/rring')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                        </Row>
                        <Row style={{paddingTop : 10}}>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/lwrist')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/rhod')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/rwrist')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                        </Row>
                        <Row style={{paddingTop : 10}}>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/dd')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/dd')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                            <Col><Image src="imggg.jpg" onClick={() => {navigate('/setting/badge')}} width={"100%"} height={"auto"} rounded ></Image></Col>
                        </Row>
                    </Card>
                </Col>
                <Col>
                    <Card Body className='UsernameBox'>
                        <Routes>
                            <Route path="/weapon" element = {<WeaponEqu/>}></Route>
                            <Route path="/up" element = {<UpEqu/>}></Route>
                            <Route path="/low" element = {<LowEqu/>}></Route>
                            <Route path="/hand" element = {<HandEqu/>}></Route>
                            <Route path="/leg" element = {<LegEqu/>}></Route>
                            <Route path="/head" element = {<HeadEqu/>}></Route>
                            <Route path="/earing" element = {<EaringEqu/>}></Route>
                            <Route path="/belt" element = {<BeltEqu/>}></Route>
                            <Route path="/lring" element = {<LringEqu/>}></Route>
                            <Route path="/rring" element = {<RringEqu/>}></Route>
                            <Route path="/neck" element = {<NeckEqu/>}></Route>
                            <Route path="/sub" element = {<SubEqu/>}></Route>
                            <Route path="/charm" element = {<CharmEqu/>}></Route>
                            <Route path="/arti" element = {<ArtiEqu/>}></Route>
                            <Route path="/rhod" element = {<RhodEqu/>}></Route>
                            <Route path="/lwrist" element = {<LwristEqu/>}></Route>
                            <Route path="/rwrist" element = {<RwristEqu/>}></Route>
                            <Route path="/badge" element = {<BadgeEqu/>}></Route>
                        </Routes>
                        
                    </Card>
                </Col>
                <Col xs = {3}>
                    <Row>
                        <Card Body className='UsernameBox'>캐릭터 닉네임 : {닉네임}<br/>이전스탯</Card>
                    </Row>
                    <Row style={{marginTop : 10}}>
                        <Card Body className='UsernameBox'>셋팅한 새로운 스탯</Card>
                    </Row>
                </Col>
            </Row>
            <Row style={{margin : 10}}>
                <Tabs
                    defaultActiveKey="arru"
                    id="fast-dungen-cut"
                    className="mb-3"
                    justify
                    >
                    <Tab eventKey="mile" title="밀레시안">
                        밀순 빠전컷 표시용
                    </Tab>
                    <Tab eventKey="arru" title="아르드리">
                        알순 빠전컷 표시용
                    </Tab>
                    <Tab eventKey="ddddd" title="오르나">
                        오르나 빠전컷 표시용
                    </Tab>
                    <Tab eventKey="surrang" title="와드네 / 시공">
                        스랭, 시공 컷 표시용
                    </Tab>
                </Tabs>
            </Row>
        </Container>
        </>
    )

    function WeaponEqu(){
        return(
            <Container>
                <Row><Col>무기</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function SubEqu(){
        return(
            <Container>
                <Row><Col>보조장비(방패, 수호부 등)</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function HeadEqu(){
        return(
            <Container>
                <Row><Col>모자</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function UpEqu(){
        return(
            <Container>
                <Row><Col>상의</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function LowEqu(){
        return(
            <Container>
                <Row><Col>하의</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function HandEqu(){
        return(
            <Container>
                <Row><Col>장갑</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function LegEqu(){
        return(
            <Container>
                <Row><Col>신발</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function EaringEqu(){
        return(
            <Container>
                <Row><Col>귀걸이</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function BeltEqu(){
        return(
            <Container>
                <Row><Col>벨트</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function LringEqu(){
        return(
            <Container>
                <Row><Col>반지(좌)</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }
    function RringEqu(){
        return(
            <Container>
                <Row><Col>반지(우)</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function NeckEqu(){
        return(
            <Container>
                <Row><Col>목걸이</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function ArtiEqu(){
        return(
            <Container>
                <Row><Col>아티펙트</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function RhodEqu(){
        return(
            <Container>
                <Row><Col>로드</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function CharmEqu(){
        return(
            <Container>
                <Row><Col>브로치</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function LwristEqu(){
        return(
            <Container>
                <Row><Col>팔찌(좌)</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function RwristEqu(){
        return(
            <Container>
                <Row><Col>팔찌(우)</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }

    function BadgeEqu(){
        return(
            <Container>
                <Row><Col>배지</Col></Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>생도뱃지나 뭐나</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row>{/* 장비 부위별로 다르게 */}
                    <Col>강화</Col>
                    <Col>접두</Col>
                    <Col>접미</Col>
                    <Col>품질</Col>
                </Row>
                <Row><Col>연마</Col></Row>{/* 연마 있는 아이템만 표시 */}
            </Container>
        )
    }
}

export default SettingPage